import React from "react";
import PropTypes from "prop-types";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  SubFeatureHeader,
  StandOutFeatureSection,
  FeaturesSection,
  CTASection,
} from "../components/pageShared";

import {
  enSmartQAAppProjectsOverviewFull,
  jaSmartQAAppProjectsOverviewFull,
  enSmartQAAppProjectDetailCrop,
  jaSmartQAAppProjectDetailCrop,
  enSmartQAAppProjectsOverviewCrop,
  jaSmartQAAppProjectsOverviewCrop,
  enSmartQAAppUploadsCrop,
  jaSmartQAAppUploadsCrop,
} from "../assetsContent";

/**
 * SecurityPage
 */
const SecurityPage = ({ data }) => {
  const {
    company,
    appLoginUrl,
    appRegisterUrl,
    companyMailToUrl,
  } = data.site.siteMetadata;

  const intl = useIntl();
  const _subHeaderTitle = intl.formatMessage({
    id: "content.security.subFeatureHeader.title",
  });
  const _subHeaderText = intl.formatMessage({
    id: "content.security.subFeatureHeader.text",
  });

  const _standOutFeature1SectionTitle = intl.formatMessage({
    id: "content.security.standOutFeature1Section.sectionTitle.title",
  });
  const _standOutFeature1Title = intl.formatMessage({
    id: "content.security.standOutFeature1Section.title",
  });
  const _standOutFeature1Text = intl.formatMessage({
    id: "content.security.standOutFeature1Section.text",
  });

  const _standOutFeature2SectionTitle = intl.formatMessage({
    id: "content.security.standOutFeature2Section.sectionTitle.title",
  });
  const _standOutFeature2Title = intl.formatMessage({
    id: "content.security.standOutFeature2Section.title",
  });
  const _standOutFeature2Text = intl.formatMessage({
    id: "content.security.standOutFeature2Section.text",
  });

  const _standOutFeature3SectionTitle = intl.formatMessage({
    id: "content.security.standOutFeature3Section.sectionTitle.title",
  });
  const _standOutFeature3Title = intl.formatMessage({
    id: "content.security.standOutFeature3Section.title",
  });
  const _standOutFeature3Text = intl.formatMessage({
    id: "content.security.standOutFeature3Section.text",
  });

  const _featuresSectionTitle = intl.formatMessage({
    id: "content.security.moreFeaturesSection.sectionTitle.title",
  });

  const _feature1Title = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block1.title",
  });
  const _feature1Text = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block1.text",
  });
  const _feature1ButtonLabel = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block1.buttonLabel",
  });

  const _feature2Title = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block2.title",
  });
  const _feature2Text = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block2.text",
  });
  const _feature2ButtonLabel = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block2.buttonLabel",
  });

  const _feature3Title = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block3.title",
  });
  const _feature3Text = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block3.text",
  });
  const _feature3ButtonLabel = intl.formatMessage({
    id: "content.security.moreFeaturesSection.block3.buttonLabel",
  });

  return (
    <IntlContextConsumer>
      {({ language }) => (
        <Layout navBarTheme="dark" navBarColor={theme.colors.accent1}>
          <SEO title="Security" />
          <SubFeatureHeader
            siteProps={{ company, appLoginUrl, appRegisterUrl }}
            title={_subHeaderTitle}
            text={_subHeaderText}
            image={
              language === "en"
                ? enSmartQAAppProjectsOverviewFull
                : jaSmartQAAppProjectsOverviewFull
            }
            bgColor={theme.colors.accent1}
          />
          <StandOutFeatureSection
            sectionId="feature1"
            sectionTitle={_standOutFeature1SectionTitle}
            title={_standOutFeature1Title}
            text={_standOutFeature1Text}
            image={
              language === "en"
                ? enSmartQAAppProjectDetailCrop
                : jaSmartQAAppProjectDetailCrop
            }
            bgColor={theme.colors.bg2}
            sectionTitleColor={theme.colors.accent1}
          />
          <StandOutFeatureSection
            sectionId="feature2"
            sectionTitle={_standOutFeature2SectionTitle}
            title={_standOutFeature2Title}
            text={_standOutFeature2Text}
            image={
              language === "en"
                ? enSmartQAAppProjectsOverviewCrop
                : jaSmartQAAppProjectsOverviewCrop
            }
            bgColor={theme.colors.bg3}
            sectionTitleColor={theme.colors.accent1}
            isReverse="true"
          />
          <StandOutFeatureSection
            sectionId="feature3"
            sectionTitle={_standOutFeature3SectionTitle}
            title={_standOutFeature3Title}
            text={_standOutFeature3Text}
            image={
              language === "en"
                ? enSmartQAAppUploadsCrop
                : jaSmartQAAppUploadsCrop
            }
            bgColor={theme.colors.colorBg2}
            sectionTitleColor={theme.colors.accent1}
          />
          <FeaturesSection
            sectionId="moreFeatures"
            sectionTitle={_featuresSectionTitle}
            block1Title={_feature1Title}
            block1Text={_feature1Text}
            block1Image={
              language === "en"
                ? enSmartQAAppProjectsOverviewCrop
                : jaSmartQAAppProjectsOverviewCrop
            }
            block1ButtonLabel={_feature1ButtonLabel}
            block2Title={_feature2Title}
            block2Text={_feature2Text}
            block2Image={
              language === "en"
                ? enSmartQAAppProjectDetailCrop
                : jaSmartQAAppProjectDetailCrop
            }
            block2ButtonLabel={_feature2ButtonLabel}
            block3Title={_feature3Title}
            block3Text={_feature3Text}
            block3Image={
              language === "en"
                ? enSmartQAAppUploadsCrop
                : jaSmartQAAppUploadsCrop
            }
            block3ButtonLabel={_feature3ButtonLabel}
          />
          <CTASection
            siteProps={{ appLoginUrl, appRegisterUrl }}
            diagonbalBgColor="bg1"
          />
        </Layout>
      )}
    </IntlContextConsumer>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        company
        appLoginUrl
        appRegisterUrl
        companyMailToUrl
      }
    }
  }
`;

export default SecurityPage;
